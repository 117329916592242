@import url("//hello.myfonts.net/count/38f655");

@font-face {
	font-family: 'HamburgTS';
	font-weight: 300;
	src: url("../fonts/38F655_D_0.eot");
	src: url("../fonts/38F655_D_0.eot?#iefix") format("embedded-opentype"), url("../fonts/38F655_D_0.woff2") format("woff2"), url("../fonts/38F655_D_0.woff") format("woff"), url("../fonts/38F655_D_0.ttf") format("truetype"); }

@font-face {
	font-family: 'HamburgTS';
	font-weight: 500;
	src: url("../fonts/38F655_8_0.eot");
	src: url("../fonts/38F655_8_0.eot?#iefix") format("embedded-opentype"), url("../fonts/38F655_8_0.woff2") format("woff2"), url("../fonts/38F655_8_0.woff") format("woff"), url("../fonts/38F655_8_0.ttf") format("truetype"); }

@font-face {
	font-family: 'HamburgTS';
	font-weight: 700;
	src: url("../fonts/38F655_A_0.eot");
	src: url("../fonts/38F655_A_0.eot?#iefix") format("embedded-opentype"), url("../fonts/38F655_A_0.woff2") format("woff2"), url("../fonts/38F655_A_0.woff") format("woff"), url("../fonts/38F655_A_0.ttf") format("truetype"); }

@import "~normalize.css/normalize.css";
@import "~suitcss-base/lib/base.css";

// you need this var for font-awesome
$fa-font-path: "~@fortawesome/fontawesome-pro-webfonts/webfonts";
@import "~@fortawesome/fontawesome-pro-webfonts/scss/fontawesome";
@import "~@fortawesome/fontawesome-pro-webfonts/scss/fa-brands";
@import "~@fortawesome/fontawesome-pro-webfonts/scss/fa-light";
@import "~@fortawesome/fontawesome-pro-webfonts/scss/fa-regular";
// @import ~@fortawesome/fontawesome-pro-webfonts/scss/fa-solid
// Uncomment the above if you need to use different fonts

@import "~magnific-popup/dist/magnific-popup.css";

@import "fonts";
